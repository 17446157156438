import React, { Component } from 'react';
import { fabric } from 'fabric';
import { GlobalContext } from '../config/global';
import UtilsCanvas from '../utils/UtilsCanvas';
import UtilsObject from '../utils/UtilsObject';
import Exports from './Exports';
import Artboard from './lib/Artboard';
import Register from './lib/Register';
import Scaling from './lib/Scaling';
import Type from './lib/Type';
import GlobalEvents from './../events/GlobalEvents';
import { object } from 'prop-types';

class CanvasArtboard extends Component {

  static contextType = GlobalContext;

  //----------------------------------------------
  // Class Fabric.js:
  // this.canvasFabric
  // this.canvasFabric.add(...) 
  //----------------------------------------------
  // HTML Canvas Element:
  // this.canvas <canvas ref={}/>
  //----------------------------------------------

  state = {
    name: (this.props && this.props.name) || 'canvas', // canvas name
    setiings: this.props.setiings || {}, // values setiings
    global: this.props.global || {}, // values global
    artboard: new Artboard(),
    history: new Register(),
    scaling: new Scaling(),
    type: new Type(),
  }

  constructor(props) {
    super(props);
    this.logg = this.logg.bind(this);
    this.update = this.update.bind(this);
    this.resize = this.resize.bind(this);
    this.create = this.create.bind(this);
    this.activeObject = this.activeObject.bind(this); 
    this.activeObjectChanged = this.activeObjectChanged.bind(this); 
  }

  logg (...args) {
    console.log(...args);
  };

  // return props update canvas refresh
  // <CanvasArtboard update={getCanvas} name="mycanvas" />
  update () {
    this.logg('update:fire');
    const data = {
      name: this.canvasFabric.name,
      length: this.canvasFabric._objects.length,
      canvas: this.canvasFabric,
    };
    this.props.update && this.props.update(data);
    this.canvasFabric.fire('canvas:update', data); 
    /* canvas.on('canvas:update', ({canvas}) => {); */
    // this.canvasFabric.fire('active:object', this.activeObject);
    this.activeObjectChanged();
  };

  loop = {
    canvas: {
      mouseup: function(options) {
        // loop all objects
        this.isFabricCanvas && this.forEachObject((object, index) => {
          // lopp all funcions inside "loop.canvas.mouseup"
          this.loop.canvas.mouseup.forEach((f) => {
            typeof f.fn === 'function' && f.fn({
              c: this, options, object, index, activeObjects: undefined,
            });
            console.log(f.name);
          });
        });
      },
    },
    // colective loop functions
    // insira funcoes nesta array 
    // "c.loop.[local]canvas.[fire]mouseup"
    // "c.loop.[local]objects.[fire]mouseup"
    // sera executada em casa loop/fire
    generic: function(local, fire) {
      if(!local || !fire) return () => {};
      return function(options) {
        // loop all objects
        this.isFabricCanvas && this.forEachObject((object, index) => {
          // lopp all funcions inside "loop.[local]canvas.[fire]mouseup"
          this.loop[local][fire] && this.loop[local][fire].forEach((f) => {
            typeof f.fn === 'function' && f.fn({
              c: this, options, object, index, activeObjects: undefined,
            });
            console.log(f.name);
          });
        });
      };
    },
  };

  // set settings
  settings (data){
    this.props.settings && this.props.settings({
      ...this.props.settings,
      ...data,
    });
  };

  // set global
  global (data){
    this.props.global && this.props.global({
      ...this.props.global,
      ...data,
    });
  }

  resize () {
    // navigator
    const { innerWidth, innerHeight } = window;
    // canvas
    const { width, height } = this.canvasFabric;
    // if changed
    if( width !== innerWidth || height !== innerHeight ){
      this.canvasFabric.setWidth(innerWidth);
      this.canvasFabric.setHeight(innerHeight);
    }    
  }
  
  selection = (event) => {
    console.log('selection:start');
    const o = this.state.type.objects(this.canvasFabric, event);
    o && console.log(o.key, o.clipPathKey, o.clipPath);
    this.canvasFabric.fire('active:object', this.activeObject);
    this.activeObjectChanged();
  }

  created = (event) => {
    console.log('object:created');
    // TODO this.createLayer();
  }

  modified = (event) => {
    console.log('object:modified');
    this.canvasFabric.fire('active:object', this.activeObject);
    this.activeObjectChanged();
    fabric.Canvas.prototype.history.register();
  }

  cleared = (event) => {
    this.canvasFabric.fire('active:object', this.activeObject);
    this.activeObjectChanged();
  }

  activeObject = ( defineObject ) => {
    return;
    return new Promise((resolve,reject) => {

      try {

        if (!defineObject){
          return resolve(defineObject);
        } else if (!this.canvasFabric){
          return reject(null);
        } else {  
          const objects = this.canvasFabric.getActiveObject();
          if (!objects){
            return reject(null);
          } else if (objects.length === 0){
            return reject(null);
          } else {
            this.canvasFabric.renderAll();
            return resolve( objects );
          }
        }
  
      } catch (error) {
        resolve(null);
      }
    });
  }

  // problem this function
  activeObjectChanged = () => {
    return;

    // let objects = this.canvasFabric.getActiveObjects();
    let key = null, length = 0;

    const objects = this.canvasFabric.getActiveObject();

    if (objects && this.canvasFabric.activeObjectKey === objects.key){
      //console.log('object with same key');
      return;
    } else if (!objects && this.canvasFabric.activeObjectKey === null){
      //console.log('object is null');
      return;
    }

    const data = {
      key, length,
      activeObject: objects || null,
    };

    this.canvasFabric.activeObjectKey = key;
    this.canvasFabric.fire('active:object:changed', data);
    GlobalEvents.dispatch('changeActiveObject', data); // temporary

  }

  create = () => {

    this.canvasFabric = new fabric.Canvas(this.canvas, {

        name: this.state.name,
        isFabricCanvas: true, // ajuda a identificar se o object é Fabric.js

        preserveObjectStacking: true, // quando seleciona o object ele vai pra frente ou nao
        controlsAboveOverlay: true, // transform fora do clip
        originX: "left",
        originY: "top",
        // backgroundColor: '#EEEEEE', //this.state.colors.backgroundColor,
        width: this.props.width,
        height: this.props.height,
        rx: 10, 
        ry: 10,

        // false to more quality, but less performance
        imageSmoothingEnabled: true,
        mozImageSmoothingEnabled: true,

        enableRetinaScaling: true, // When true, canvas is scaled by devicePixelRatio for better rendering on retina screens 
        renderOnAddRemove: false, // http://fabricjs.com/docs/fabric.Canvas.html#renderOnAddRemove

        fireRightClick: true,
        stopContextMenu: false,
        selection: true,

        // to colletive loops functions
        // insert your funcion here
        // this.loop.canvas.mouseup.push({name:'function', fn:()=>{}});
        loop: {
          canvas: {
            mouseup: [
              {name: 'qualquer coisa 1', fn: ({c, options, object}) => console.log(c.name, options, object.typeName)},
              // {name: 'qualquer coisa 2', fn: ({c, options, object}) => console.log(object.width)},
              // {name: 'qualquer coisa 3', fn: ({c, options, object}) => console.log(object.height, options.pointer)},
            ],
            mousedown: [],
          }
        },

        // quando o objeto estiver dentro de uma clipPath, só consegue mover o objeto na area do clippath
        // http://fabricjs.com/docs/fabric.Object.html#perPixelTargetFind
        // perPixelTargetFind: true,

    });

    fabric.Object.prototype.objectCaching = false; // if false not smooth, but slow when have many objects
    
    // ------------------------------------------------------------------------------------- selection
    // Set this onResize | update devicePixelRatio 
    fabric.devicePixelRatio = fabric.window.devicePixelRatio || fabric.window.webkitDevicePixelRatio || fabric.window.mozDevicePixelRatio || 1;
    this.canvasFabric._initRetinaScaling();
    this.canvasFabric.requestRenderAll(); // re-render canvas with new quality

    // ------------------------------------------------------------------------------------- selection
    // retângulo de seleção de objetos, mostra a área quando se está selecionando mais de um objeto
    fabric.Object.prototype.selection = true;  
    // this.canvasFabric.selectionColor = 'rgba(0,255,0,0.3)';
    // this.canvasFabric.selectionBorderColor = 'red';
    // this.canvasFabric.selectionLineWidth = 5;
    // this.canvasFabric.selectionDashArray = [5, 5];

    // ------------------------------------------------------------------------------------- corners
    fabric.Object.prototype.padding = 0; // padding transform-corners offset object
    fabric.Object.prototype.cornerColor = '#00AAE4'; // '#04D9FF';
    fabric.Object.prototype.borderColor = '#00AAE4'; // '#04D9FF';
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.cornerSize = 11;

    // ------------------------------------------------------------------------------------- draw
    // this.canvasFabric.isDrawingMode = true;
    // this.canvasFabric.freeDrawingBrush.width = 2;
    // this.canvasFabric.freeDrawingBrush.color = "#ff0000";

    console.log('Version: ', fabric.version);

    // var DefaultGroupSettings = {
    //   originX: 'center',
    //   originY: 'center',
    //   hasBorders: true,
    //   hasControls: true,
    //   hasRotatingPoint: true,
    //   lockUniScaling: true
    // };
    // var group = new fabric.Group([rect, circle], DefaultGroupSettings);

    fabric.Object.prototype.toSVGURI = function(object) {
      return `data:image/svg+xml;utf8,${encodeURIComponent(this.toSVG())}`
    };
    
    // fabric.Image.prototype.toDataURLforSVG = function(object, options) {
    //   var el = fabric.util.createCanvasElement();
    //         el.width  = object.naturalWidth || object.width;
    //         el.height = object.naturalHeight || object.height;
    //   el.getContext("2d").drawImage(object, 0, 0);
    //   var data = el.toDataURL(options);
    //   return data;
    // };

    // is a fake history 
    fabric.Canvas.prototype.history || (fabric.Canvas.prototype.history = {
      init: () => {},
      keysDown:() => {},
      reset: () => {},
      register: () => {},
      undo: () => {},
      redo: () => {},
    });

    // init layers tree
    fabric.Canvas.prototype.layersTree || (fabric.Canvas.prototype.layersTree = {
      listInside: [],
      listOutside: [],
    });

    // fast get canvas
    fabric.Canvas.prototype.canvas = (idCanvas) => idCanvas === this.canvasFabric.name ? this.canvasFabric : null;
    // resize canvas
    fabric.Canvas.prototype.resize = this.resize;
    // fabric.Canvas.prototype.resize = () => this.resize;
    
    // update by prototype
    fabric.Canvas.prototype.update = (idCanvas) => new Promise((resolve, reject) => {
      try {
        // check if is my canvas working
        idCanvas === this.canvasFabric.name ? resolve({
          name: this.canvasFabric.name,
          length: this.canvasFabric._objects.length,
          canvas: this.canvasFabric,
        }) : reject({error: 'other-canvas-id-name'});
      } catch (error) {
        reject(error);          
      }
    });

    // TODO test
    fabric.Canvas.prototype.activeObject = this.activeObject;
    
    // filters
    fabric.initFilterBackend = function() {
      if (fabric.enableGLFiltering &&  fabric.isWebglSupported && fabric.isWebglSupported(fabric.textureSize)) {
        console.log('MaxTextureSize: ' + fabric.maxTextureSize);
        return (new fabric.WebglFilterBackend({ tileSize: fabric.textureSize }));
      }
      else if (fabric.Canvas2dFilterBackend) {
        return (new fabric.Canvas2dFilterBackend());
      }
    };
    fabric.filterBackend = fabric.initFilterBackend();

    // selection
    this.canvasFabric.on({
      'object:scaling': this.state.scaling.objects,
      'object:modified': this.modified,
      'selection:created': this.selection, 
      'selection:updated': this.selection, 
      'selection:cleared': this.cleared, 
    });

    // collective loop
    this.canvasFabric.on({
      // need add functions inside c.loop.canvas.mouseup | mouseover | mousemove | render
      'mouse:down': this.loop.generic('canvas', 'mousedown'),
      'mouse:up': this.loop.generic('canvas', 'mouseup'),
      'mouse:over': this.loop.generic('canvas', 'mouseover'),
    });

    /* 
    *  Artboard
    */
    
    // create artboard
    this.state.artboard.addEventListenerArtboard(this.canvasFabric);

    // ***
    // EU DEVERIA ATIVAR ESSAS FUNCOES DE ARRASTA ABAIXO SOMENTE QUANDO CLICAR NO ESPAÇO OU ALT
    // ESTA OCORRENDO QUE ESTOU SEMPRE ENTRANDO NESTAS FUNCOES SEM QUEREER, MESMO NAO ARRASTANDO O ARTBOARD
    // ***

    // move artboards Alt + Click
    // LER NOVAMENTE
    //https://stackoverflow.com/questions/34423822/how-to-implement-canvas-panning-with-fabric-js
    //https://github.com/SabatinoMasala/fabric-clipping-demo/blob/master/src/classes/Panning.js
    this.canvasFabric.on('mouse:down', function(opt) {     
      const evt = opt.e;
      if(opt.target !== null) return; // NAO ENTRA SE TIVER SOBRE UM OBJECTO
      // console.log('opt.target', opt.target);
      // console.log(this.keySpace);
      if (evt.altKey === true || this.keySpace) {  
        // discart object when move artboard to not change mask
        this.discardActiveObject();
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    // Alt + Move Mouse
    this.canvasFabric.on('mouse:move', function(opt) {
      const evt = opt.e;
      // console.log('opt.target', opt.target);
      // console.log(this.keySpace);
      //if(opt.target !== null) return; // NAO ENTRA SE TIVER SOBRE UM OBJECTO, porem se deixar fica travando pois ele encontra clippath dos artboard

      if (this.isDragging === true && (evt.altKey === true || this.keySpace)) {
        // discart object when move artboard to not change mask
        this.discardActiveObject();
        var vpt = this.viewportTransform;
        vpt[4] += evt.clientX - this.lastPosX;
        vpt[5] += evt.clientY - this.lastPosY;
        this.requestRenderAll();
        this.renderAll();
        // updateMiniMapVP();
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    // Mouse Up
    this.canvasFabric.on('mouse:up', function(opt) {
      if (this.isDragging === true){
        this.isDragging = false;
        this.selection = true;
        this.calcOffset(); // calc artbord position x,y canvas
        this.forEachObject( el => el.setCoords() ); // update coords
        // this.requestRenderAll(); // render all objects  
      }
      // NAO INDICADO
      // TEM MUITO LOOP NO CANVAS
      this.forEachObject( el => el.typeName === 'ArtboardTitle' && this.bringToFront(el) )

    });
  
    // zoom artboard
    this.canvasFabric.on('mouse:wheel', function( opt ){

      try {

        // remover rect highlight over
        this.clearContext(this.contextTop);

        // init calc
        let delta = opt.e.deltaY;
        let zoom = this.getZoom();
        zoom = zoom + delta/2000;
        if (zoom > 5) zoom = 5;
        if (zoom < 0.1) zoom = 0.1;
        console.log(zoom);
        this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

        // artboard stroke scalable
        this.forEachObject( 
          el => el.typeName === 'Artboard' && el.set({
            strokeWidth: 1/zoom,
            top: el.top >> 0,
            left: el.left >> 0,
          })
        );

        // this.update(); // Class      
        this.renderAll();
        // this.requestRenderAll();
        // this.canvasFabric.requestRenderAll(); // re-render canvas with new quality
        // this.canvasFabric.renderAll();
        // GlobalEvents.dispatch('changeZoom',{value:zoom});

      } catch (error) {
        console.log(error);
      }

      opt.e.preventDefault();
      opt.e.stopPropagation();

    });
    
    /*
    // v 4.3.1

    Fire to Canvas

    object:modified at the end of a transform or any change when statefull is true
    object:rotating while an object is being rotated from the control
    object:scaling while an object is being scaled by controls
    object:moving while an object is being dragged
    object:skewing while an object is being skewed from the controls
    before:transform before a transform is is started
    before:selection:cleared
    selection:cleared
    selection:updated
    selection:created
    path:created after a drawing operation ends and the path is added
    mouse:down
    mouse:move
    mouse:up
    mouse:down:before on mouse down,event: before the inner fabric logic runs
    mouse:move:before on mouse move,event: before the inner fabric logic runs
    mouse:up:before on mouse up,event: before the inner fabric logic runs
    mouse:over
    mouse:out
    mouse:dblclick whenever a native dbl click event fires on the canvas.
    event:dragover
    event:dragenter
    event:dragleave
    event:drop
    after:render at the end of the render process,event: receives the context in the callback
    before:render at start the render process, receives the context in the callback the following events are deprecated:event:
    object:rotated at the end of a rotation transform
    object:scaled at the end of a scale transform
    object:moved at the end of translation transform
    object:skewed at the end of a skew transform

    Fire to Object

    event:added
    event:removed
    event:selected
    event:deselected
    event:modified
    event:modified
    event:moved
    event:scaled
    event:rotated
    event:skewed
    event:rotating
    event:scaling
    event:moving
    event:skewing
    event:mousedown
    event:mouseup
    event:mouseover
    event:mouseout
    event:mousewheel
    event:mousedblclick
    event:dragover
    event:dragenter
    event:dragleave
    event:drop
    */

    // this.canvasFabric.on('after:render', function() {
    //   this.contextContainer.strokeStyle = '#555';
  
    //   this.forEachObject((obj) => {
    //     var bound = obj.getBoundingRect();
  
    //     this.contextContainer.strokeRect(
    //       bound.left + 0.5,
    //       bound.top + 0.5,
    //       bound.width,
    //       bound.height
    //     );
    //   })
    // });

    // ----------------------------------------------------------------------- over highlight obhect

    this.canvasFabric.on('mouse:over', function(options) {
      try {
        let object = options.target || options.currentTarget;
        object && object._renderControls(this.contextTop, {
          hasControls: false
        });
      } catch (error) {
        console.log(error);
      }
    });

    this.canvasFabric.on('mouse:down', function() {
      try {
        this.clearContext(this.contextTop);
      } catch (error) {        
        console.log(error);
      }
    });

    this.canvasFabric.on('mouse:out', function() {
      try {
        this.clearContext(this.contextTop);
      } catch (error) {
        console.log(error);        
      }
    });

    // ----------------------------------------------------------------------- / over highlight obhect

    // clear
    //new UtilsCanvas().clear( this.canvasFabric ); // add RectResize and RectMP

    // init history
    fabric.Canvas.prototype.history.init();
    // resize Canvas
    this.resize();
    // update
    this.update();












    // fabric.Image.prototype._render = function(ctx) {

    //   // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip
    //   // const canvas = document.getElementById('canvas');
    //   // const ctx = canvas.getContext('2d');
      
    //   // // Create circular clipping region
    //   if(this.typeName === 'ImageAdvanced'){

    //     ctx.beginPath();
    //     ctx.arc(10, 10, 100, 0, Math.PI * 2);
    //     //ctx.rect(0, 0, this.width, this.height);
    //     ctx.rect(20, 20, 150, 100);
    //     // ctx.fill();
    //     ctx.clip();
    //     // The clearRect() method is used to clear a rectangle (erase the area to transparent black).   
    //     // clearRect()

        

    //     // NC
    //     // this._element.perPixelTargetFind = true;
    //     // this.perPixelTargetFind = true;

    //     // // Draw stuff that gets clipped
    //     // ctx.fillStyle = 'blue';
    //     // ctx.fillRect(0, 0, canvas.width, canvas.height);
    //     // ctx.fillStyle = 'orange';
    //     // ctx.fillRect(0, 0, 100, 100);
    //   }

    //     ctx.fillStyle = 'orange';
    //     ctx.fillRect(-150, -150, 400, 400);


    //     // draw in image to main canvas
    //     // ctx.drawImage(this, 0, 0);

    //     // // invert alpha channel
    //     // ctx.globalCompositeOperation = "xor";
    //     // ctx.fillRect(0, 0, c.width, c.height);

    //     // // draw itself again using drop-shadow filter
    //     // ctx.shadowBlur = 7*2;  // use double of what is in CSS filter (Chrome x4)
    //     // ctx.shadowOffsetX = ctx.shadowOffsetY = 5;
    //     // ctx.shadowColor = "#000";
    //     // ctx.drawImage(c, 0, 0);

    //     // // draw original image with background mixed on top
    //     // ctx.globalCompositeOperation = "destination-atop";
    //     // ctx.shadowColor = "transparent";                  // remove shadow !
    //     // ctx.drawImage(this, 0, 0);


    //   // custom clip code
    //   if (this.clipPath) {
    //     // ctx.save();
    //     // if (this.clipPath.fixed || this.clipPath.absolutePositioned) {
    //     //   var retina = this.canvas.getRetinaScaling();
    //     //   ctx.setTransform(retina, 0, 0, retina, 0, 0);
    //     //   // to handle zoom
    //     //   ctx.transform.apply(ctx, this.canvas.viewportTransform);
    //     //   //
    //     //   this.clipPath.transform(ctx);
    //     // }
    //     // this.clipPath._render(ctx);
    //     // ctx.restore();
    //     // ctx.clip();
    //   }
    //   // end custom clip code
        
    //   // var x = -this.width / 2, y = -this.height / 2, elementToDraw;
    //   // if (this.isMoving === false && this.resizeFilter && this._needsResize()) {
    //   //   this._lastScaleX = this.scaleX;
    //   //   this._lastScaleY = this.scaleY;
    //   //   this.applyResizeFilters();
    //   // }
    //   // elementToDraw = this._element;
      
    //   // elementToDraw && ctx.drawImage(elementToDraw,
    //   //   0, 0, this.width, this.height,
    //   //   x, y, this.width, this.height);
                    
    //   // this._stroke(ctx);
    //   // this._renderStroke(ctx);

    //   // copy fabricjs original
    //   fabric.util.setImageSmoothing(ctx, this.imageSmoothing);
    //   if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
    //     this.applyResizeFilters();
    //   }
    //   this._stroke(ctx);
    //   this._renderPaintInOrder(ctx);

    //   ctx.fillStyle = '#FF00FF44';
    //   ctx.fillRect(-150, -150, 380, 380);

    // };
















  }

  keyDown = ( event ) => {

    UtilsObject.keyDownMove(this.canvasFabric, event);
    UtilsObject.keyDownDelete(this.canvasFabric, event);
    this.state.artboard.keysDown(this.canvasFabric, event);
    this.state.history.keysDown(this.canvasFabric, event);
    new Exports().keyDownControlSave(this.canvasFabric, event);    
  
  }

  keyUp = ( event ) => {
    this.state.artboard.keysUp(this.canvasFabric, event);
  }

  componentDidMount() {
    
    try {
      // init fabric canvas
      this.create();      
      // resize canvas
      this.resize();
    } catch (error) {
      console.log('create', error);
    }

    // componentDidMount
    console.log("componentDidMount");
    // GlobalEvents.on('canvasToGlobal', this.canvasToGlobal) ;
    // GlobalEvents.on('changeClipPath', this.resizeRectMP );
    GlobalEvents.on('resizeCanvas', this.resize );
    window.addEventListener("resizeCanvas", this.resize );
    window.addEventListener("keydown", this.keyDown, false );
    window.addEventListener("keyup", this.keyUp, false );
  }

  componentWillUnmount(){
    // componentWillUnmount
    console.log("componentWillUnmount");
    // GlobalEvents.off('canvasToGlobal', this.canvasToGlobal) ;
    // GlobalEvents.off('changeClipPath', this.resizeRectMP );
    GlobalEvents.off('resizeCanvas', this.resize );
    window.removeEventListener("resize", this.resize );
    window.removeEventListener("keydown", this.keyDown, false );
    window.removeEventListener("keyup", this.keyUp, false );
  }

  componentDidUpdate() {
    
    this.resize();

    //-------------------------------------------------------------------------------------
    // send to GLOBAL temporary
    // sent to global.js the Fabric Canvas (this file)
    //-------------------------------------------------------------------------------------
    // window.canvas = this.canvasFabric;
    // this.canvasToGlobal();
    //-------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------
    // este recurso é para enviar o acesso ao this.canvasFabric para o props

    // Access this.props
    // this.props.canvas : Created a variable named canvas|c|fabric|anyway...
    // this.canvasFabric : send fabric canvas to props

    //this.props.canvas(this.canvasFabric);
    //this.props.setSettings((prev)=>{return {...prev,canvas: this.canvasFabric }});
    //-------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------
    // este recurso é para recever this.canvasFabric pelo props

    // <Component canvas={(c) => setCanvas(c)}>
    // <Component : this Class
    // canvas= : name props / canvas|getCanvas|seCanvas|anyway...
    // (c) : this.canvasFabric
    // setCanvas(c) : function parent component
    // update canvas props, call function setCanvas
    //-------------------------------------------------------------------------------------
  }

  render() {
    return (
      <div onClick={this.update} onMouseOver={()=>UtilsCanvas.focus(this.state.name)}>
        <canvas id={this.state.name} tabIndex="0" ref={me => (this.canvas = me)} />
      </div>
    );
  }
}

export default CanvasArtboard;
