import React from 'react';
import Structure from './Structure';
import GlobalProvider from '../components/config/global'  
import ArtboardProvider from '../components/fabricjs/ArtboardContext';

// import CanvasPaper from './../components/paper/CanvasPaper';
// import CanvasPaper2 from './../components/paper/CanvasPaper2';

const App = () => {
  return (
    <ArtboardProvider>
      <GlobalProvider>
        <Structure/>
        {/* <CanvasPaper/> */}
        {/* <CanvasPaper2/> */}
      </GlobalProvider>
    </ArtboardProvider>
  )
};
export default App; 