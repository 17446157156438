import React from "react";
import UtilsCanvas from "./../utils/UtilsCanvas";
import { fabric } from "fabric";
// import GlobalEvents from "../event/GlobalEvents";

class Images extends React.Component {
  
  add = ( c, settings, callback ) => {

    if ( typeof settings !== 'object' ) return;

    let { imageSmoothing, name, source, typeName, crossOrigin,
      crop, cropX, cropY, cropWidth, cropHeight, perPixelTargetFind, // rect crop
      originX, originY, 
      angle, opacity, fill, scale, backgroundColor,
      lockScalingFlip, selectable, hasControls,  
      left, top, relativePosition, 
      _render, drawObject, transform,
     } = settings ;

    const extension = source.split('.').pop().toLowerCase();
  
    const setting = {

      crossOrigin: crossOrigin || 'anonymous',
      key: UtilsCanvas.key(),
      name: name || '',
      typeName: typeName || 'Image',

      left: relativePosition === false ? left : UtilsCanvas.setPositionLeft( c, left )  ,
      top: relativePosition === false ? top : UtilsCanvas.setPositionTop( c, top ) ,
      hasControls: hasControls !== undefined ? hasControls : true ,
      selectable: selectable !== undefined ? selectable : true ,
      lockScalingFlip: lockScalingFlip !== undefined ? lockScalingFlip : true ,
      scaleXY: scale !== undefined ? scale : 1 , 
      angle: angle || 0,
      fill,
      backgroundColor,
      opacity: opacity !== undefined ? opacity : 1 ,
      imageSmoothing: imageSmoothing !== undefined ? imageSmoothing : true,

      // context ctx
      _renderer: _render,
      _drawObject: drawObject,
      _transform: transform,

      // clipath  crop
      crop, cropWidth, cropHeight, perPixelTargetFind, 
      cropLeft: cropX, cropTop: cropY,

      // NAO SETAR ALTURA E LARGURA DA IMAGEM INICIALMENTE
      // scaleToWidth: width,
      // scaleToHeight:height,        
    }

    // load base64
    if( source.indexOf('data:image') > -1 ) {

      var image = new Image();
      // When the image loads, set it as background image
      image.onload = () => this.finallyDone( c, new fabric.Image(image), {...setting, typeName: 'Image'}, callback );
      // Set the src of the image with the base64 string
      image.src = source;

    } else if ( extension === 'svg' ) {

      // load svg
      fabric.loadSVGFromURL( source, ( objects, options ) => {
        // console.log('fabric.loadSVGFromURL', source, objects );        
        // objects.map( item => { return item.fill = '#000000' } );
        // const o = [...objects];
        // o.forEach( elements => {
        //   elements.top  += 200;
        //   elements.left += 200;
        //   c.add(elements);
        //   elements.setCoords();
        // });
        // o.distroy();
        this.finallyDone( c, fabric.util.groupSVGElements( objects, options ), {...setting, typeName: 'SVG'}, callback );
      });
  
    } else {

      // load image
      // fabric.util.loadImage
      fabric.Image.fromURL( source, ( image ) => this.finallyDone( c, image, {...setting}, callback ), { crossOrigin: 'anonymous' });

    }

    //var path2 = new fabric.Path('M178.53,77H8.19V6.3H178.53ZM9.6,75.55H177.11V7.72H9.6Z');
    //UtilsCanvas.getCanvas(c).add(path2.set({ left: 100, top: 200 }));

  };

  finallyDone = ( c, image, setting, callback ) => {

    const { 
      index, width, height, scaleXY,
      crop, cropLeft, cropTop, cropWidth, cropHeight, 
      // context ctx
      _renderer, _drawObject, _transform,
    } = setting ;

    // DONT REMOVE THIS setting, please
    image.set( setting, { crossOrigin: "anonymous" },);

    index !== undefined && image.moveTo( index );
    scaleXY && image.scale(scaleXY);
    width && image.scaleToWidth(width);
    height && image.scaleToHeight(height);
        
    if(cropLeft && cropTop && cropWidth && cropHeight){
      image.set({width: cropWidth, height: cropHeight, cropX: cropLeft, cropY: cropTop});
      // quando o objeto estiver dentro de uma clipPath, só consegue mover o objeto na area do clippath
      // http://fabricjs.com/docs/fabric.Object.html#perPixelTargetFind
      // image.set({perPixelTargetFind: true,});
    } else if(crop){ 
      image.set({width: crop.width, height: crop.height, cropX: crop.x, cropY: crop.y});
    }

    typeof _renderer === 'function'   && (image._render = _renderer);
    typeof _drawObject === 'function' && (image.drawObject = _drawObject);
    typeof _transform === 'function'  && (image.transform = _transform);
    
    c.add(image);
    image.setCoords();

    console.log('Image.add:', image);

    UtilsCanvas.setActiveObject( c, image );
    UtilsCanvas.renderAll( c );

    callback && callback({ object: image });

    fabric.Canvas.prototype.history.register();

  }

  // https://jsfiddle.net/ud9nev1g/
  // document.getElementById('file').addEventListener( 'change', function(e) {});
  upload = ( c, event, settings ) => {
    
    // file
    const file = event.target.files[0] || undefined;
    var reader = new FileReader();

    if(!file) {
      window.alert('File not found.');
      return;
    }

    let {left, top, scale, width, height, centralize, _render} = settings || {};

    left  = left || UtilsCanvas.setPositionLeft( c, 10 );
    top   = top || UtilsCanvas.setPositionLeft( c, 10 );
    scale = scale || 0.2;
    
    // load file
    reader.onload = ( f ) => {
      const data = f.target.result;
      fabric.Image.fromURL( data, ( image ) => {
        // const typeName = data.substr(0,20).indexOf('svg') < 0 ? 'Image' : 'SVG' ;
        const typeName = 'Image';
        image.set({
          typeName: typeName,
          crossOrigin: 'anonymous',
          left,
          top,
          // left: UtilsCanvas.setPositionLeft( c, 10 ),
          // top: UtilsCanvas.setPositionTop( c, 10 ),
          angle: 0,
          imageSmoothingEnabled: true,  
          mozImageSmoothingEnabled: true,
          imageSmoothing: true,
        });
        
        // set dimentions
        if(width || height){
          scale = 1;
          if(width){
            image.scaleToWidth(width);
          } else {
            image.scaleToHeight(height);
          }
        } else {
          image.scale( scale );
        }

        // centralize
        if(centralize){
          left = c.width/2 - (image.width * image.scaleX)/2;
          top = c.height/2 - (image.height * image.scaleY)/2;
          image.set({left, top});
        }

        typeof _render === 'function' && (image._render = _render)
        
        c.add( image );
        c.renderAll();
        // c.requestRenderAll();

        fabric.Canvas.prototype.history.register();
      });
    };
    // load
    reader.readAsDataURL(file);
  };
  
}

export default Images;


/*

image._render = function(ctx) {

      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip
      // const canvas = document.getElementById('canvas');
      // const ctx = canvas.getContext('2d');
      
      // // Create circular clipping region
      if(this.typeName === 'ImageAdvanced'){

        ctx.beginPath();
        ctx.arc(10, 10, 100, 0, Math.PI * 2);
        //ctx.rect(0, 0, this.width, this.height);
        ctx.rect(20, 20, 150, 100);
        // ctx.fill();
        ctx.clip();
        // The clearRect() method is used to clear a rectangle (erase the area to transparent black).   
        // clearRect()

        // NC
        // this._element.perPixelTargetFind = true;
        // this.perPixelTargetFind = true;

        // // Draw stuff that gets clipped
        // ctx.fillStyle = 'blue';
        // ctx.fillRect(0, 0, canvas.width, canvas.height);
        // ctx.fillStyle = 'orange';
        // ctx.fillRect(0, 0, 100, 100);
      }      
      
      // custom clip code
      if (this.clipPath) {
        // ctx.save();
        // if (this.clipPath.fixed || this.clipPath.absolutePositioned) {
        //   var retina = this.canvas.getRetinaScaling();
        //   ctx.setTransform(retina, 0, 0, retina, 0, 0);
        //   // to handle zoom
        //   ctx.transform.apply(ctx, this.canvas.viewportTransform);
        //   //
        //   this.clipPath.transform(ctx);
        // }
        // this.clipPath._render(ctx);
        // ctx.restore();
        // ctx.clip();
      }
      // end custom clip code
        
      var x = -this.width / 2, y = -this.height / 2, elementToDraw;
      if (this.isMoving === false && this.resizeFilter && this._needsResize()) {
        this._lastScaleX = this.scaleX;
        this._lastScaleY = this.scaleY;
        this.applyResizeFilters();
      }
      elementToDraw = this._element;
      elementToDraw && ctx.drawImage(elementToDraw,
                                     0, 0, this.width, this.height,
                                     x, y, this.width, this.height);
      this._stroke(ctx);
      this._renderStroke(ctx);

      // copy fabricjs original
      fabric.util.setImageSmoothing(ctx, this.imageSmoothing);
      if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
        this.applyResizeFilters();
      }
      this._stroke(ctx);
      this._renderPaintInOrder(ctx);

    };
    */