import React from "react";
import { fabric } from "fabric"; 
import UtilsCanvas from "../../utils/UtilsCanvas";
import UtilsObject from "../../utils/UtilsObject";

const STROKE_WIDTH = 1;
const STROKE_COLOR = 'rgb(150,150,150,1)';
const FILL_COLOR = '#DDDDDD'; //'rgb(255,255,255,0.9)';
const OPACITY = 1;

/**
 * 
 * 2022 - Olhar este link
 * http://fabricjs.com/erasing
 * 
 * Basicamente ele cria um elemento vetor com ERASE, e coloca vários objetos dentro.
 * Talvez consigamos colocar elementos com máscara
 * 
 */

// Custom events
// https://github.com/fabricjs/fabric.js/wiki/Working-with-events
// Add elementps de fora do Fabric, HTML to fabric
// http://fabricjs.com/events

/**
 * 
 * Posso fazer dois tipos de clipMask para artboard
 * 1 - CliPath de um objeto existente
 * 2 - Um grande Rect com vários ClipPath
 * 
 * 1 - Usar um objeto físico, e não virtual, para 
 *     pegar a forma e aplicar como clipPath. Captar pela key.
 * 2 - Criar um grande retangulo gigante sempre na última posição, 
 *     e aplicar a máscara neste objeto, deixando-o com furos 
 *     igual um artboard.
 */

class Artboard extends React.Component {

  state = {};

  constructor(props) {
    super(props);
    this.addEventListenerArtboard = this.addEventListenerArtboard.bind(this);
    this.removeEventListenerArtboard = this.removeEventListenerArtboard.bind(this);
    this.keysDown = this.keysDown.bind(this);
    this.keysUp = this.keysUp.bind(this);
    this.onMoveObjects = this.onMoveObjects.bind(this);
    this.drawArtboad = this.drawArtboad.bind(this);
    this.addArtboard = this.addArtboard.bind(this);
  }

  // ### Scrollbars testing
  // https://github.com/fabricjs/fabric.js/issues/3164#issuecomment-241111083
  // https://github.com/fabricjs/fabric.js/issues/4042#issuecomment-459224529
  // https://github.com/fabricjs/fabric.js/issues/5435#issue-392029643
  // https://stackoverflow.com/questions/22742067/how-to-add-scrollbars-to-a-potentially-infinite-canvas-using-fabric-js
  // ### mouse move
  // https://stackoverflow.com/questions/34423822/how-to-implement-canvas-panning-with-fabric-js

  // move object inside artboard
  addEventListenerArtboard = (c) => {
    console.log('addEventListenerArtboard');
    c.on({
      'object:moving': (e) => this.onMoveObjects(c, e),
      'object:rotating': (e) => this.onMoveObjects(c, e),
      //'object:scaling': (e) => this.onMoveObjects(c, e),
      //'object:skewing': (e) => this.onMoveObjects(c, e),
      'object:modified': (e) => this.onMoveObjects(c, e),
    });
  };

  removeEventListenerArtboard = (c) => {
    console.log('removeEventListenerArtboard');
    c.off({
      'object:moving': (e) => this.onMoveObjects(c, e),
      'object:rotating': (e) => this.onMoveObjects(c, e),
      //'object:scaling': (e) => this.onMoveObjects(c, e),
      //'object:skewing': (e) => this.onMoveObjects(c, e),
      'object:modified': (e) => this.onMoveObjects(c, e),
    });
  };

  keysDown = (c, event) => {
    // Ctrl + Zero
    if ((event.keyCode === 48 || event.keyCode === 96) && event.ctrlKey) {
      try {
        // reset zoom
        c.setViewportTransform([1,0,0,1,0,0]);
        // reset stroke line of Artboard
        c.forEachObject(el => el.typeName === 'Artboard' && el.set('strokeWidth', 1));
        // remover rect highlight over
        c.clearContext(c.contextTop);
        // render all
        c.renderAll();
      } catch (error) {
        console.log(error);
      }
    } else if(event.keyCode === 32) {
      c.keySpace = true;
    } else {
      c.keySpace = false;
    }
  }  

  keysUp = (c, event) => {
    if(event.keyCode === 32) {
      c.keySpace = false;
      console.log('aaaa');
    }
  }

  onMoveObjects = (c, options) => {

    if(!options.target || !c || !c.getActiveObject()) return;

    // model tree
    const properties = {
      index: 'index',
      label: 'label',
      key: 'id',
      parentKey: 'parentId',
      children: 'items',
      isMask: 'isMask',
      object: 'object',
    }

    options.target.setCoords();

    //options.target is drag and drop object
    const listOfIntersect = [];
    // const listArtboard = [];
    const listArtboardClipPath = [];
      let tempPath = null;

    // list all artiboards to fast search
    // c.forEachObject((el) => el.typeName === 'Artboard' && listArtboard.push(el));
    c.forEachObject((el) => el.typeName === 'ArtboardClipPath' && listArtboardClipPath.push(el));

    // list all objects AND object moved
    // listArtboard.forEach(function(obj, indx) {
    c.forEachObject(function(obj, indx) {

      if (obj === options.target) return;
      
      // objects has clipPath but not a Artboard. Not apply artboard
      if (options.target.clipPath && !options.target.clipPathKey) return;

      // all objects
      // create varieble artboard on object
      obj.artboard || (obj.artboard = {}); 
      obj.artboard[properties.index]      || (obj.artboard[properties.index] = null); 
      obj.artboard[properties.key]        || (obj.artboard[properties.key] = null); 
      obj.artboard[properties.parentKey]  || (obj.artboard[properties.parentKey] = null); 
      obj.artboard[properties.object]     || (obj.artboard[properties.object] = null); 
      obj.artboard[properties.isMask]     || (obj.artboard[properties.isMask] = false); 

      // object moved
      // create varieble artboard on object moved
      options.target.artboard || (options.target.artboard = {}); 
      options.target.artboard[properties.index]     || (options.target.artboard[properties.index] = null); 
      options.target.artboard[properties.key]       || (options.target.artboard[properties.key] = null); 
      options.target.artboard[properties.parentKey] || (options.target.artboard[properties.parentKey] = null); 
      options.target.artboard[properties.object]    || (options.target.artboard[properties.object] = null); 
      options.target.artboard[properties.isMask]    || (options.target.artboard[properties.isMask] = false); 

      // Se o objeto do looping nao for um Artboad não segue em frente
      if (obj.typeName !== 'ArtboardClipPath' && obj.typeName !== 'Artboard') return;

      const {clientX, clientY} = options.e || {};
      const {left, top, width, height} = obj || {};
        let intersectsWithObject = false;

      if(clientY && clientY) {
        // Mouse over artboard
        // apenas quando o objeto está sobre o artboard, assim como o mouse
        intersectsWithObject = (left < clientX && top < clientY &&  left + width > clientX && top + height > clientY);
      } else {
        // Half/Partial Intercept
        intersectsWithObject = options.target.intersectsWithObject(obj);
      }

      // console.log(clientX, clientY, left, top, width, height);
      // quando o mouse está sobre o artboard
      // if(left < clientX && top < clientY &&  left + width > clientX && top + height > clientY) console.log('Entrou');

      // obj.set('opacity' ,options.target.intersectsWithObject(obj) ? 0.5 : 1);
      // obj.set('opacity' ,options.target.isContainedWithinObject(obj) ? 0.5 : 1);

      //Full Intercept
      if (options.target.isContainedWithinObject(obj)) {

        //################### colocar estes objectos dentro de object.artboard

        tempPath = listArtboardClipPath.find(el => el.key === obj.clipPathKey);
        options.target.set('clipPath', tempPath); // obj.clipPathObject
        // apply mask moved object
        //options.target.set('clipPath', obj.clipPathObject); // ERROR
        // INSERIR DENTRO do object.artboard:::
        options.target.set('clipPathKey', obj.clipPathKey); // este KEY é da máscara, no clipPath usado como padrão, que fica escondido
        options.target.set('clipPathTreeOwnerKey', obj.key); // este KEY, é do elemento visível, da tela em branco e do Tree/Layers
        options.target.set('clipPathTreeIndex', indx);
        options.target.set('clipPathOnlyOver', undefined); // NAO EH USADO AINDA, para objetos sobre o artboard mas outsides
        // backup
        // options.target.set('clipPathObject', obj.clipPathObject);
        // options.target.set('clipPathJson', obj.clipPathJson);
        // options.target.set('clipPathSVG', obj.clipPathSVG);
        // console.log(obj.clipPathSVG);
        listOfIntersect.push(obj);
      
      // Half/Partial Intercept
      // } else if (options.target.intersectsWithObject(obj)) {
      // Mouse over artboard
      } else if (intersectsWithObject) {
        
        // apply mask moved object
        // only if clipPath is null
        if(options.target.clipPath === undefined){ // === null
          tempPath = listArtboardClipPath.find(el => el.key === obj.clipPathKey);
          options.target.set('clipPath', tempPath); // obj.clipPathObject          
          // INSERIR DENTRO do object.artboard:::
          options.target.set('clipPathKey', obj.clipPathKey);
          options.target.set('clipPathTreeOwnerKey', obj.key);
          options.target.set('clipPathTreeIndex', indx);
          options.target.set('clipPathOnlyOver', undefined); // NAO EH USADO AINDA, para objetos sobre o artboard mas outsides
          // backup
          // options.target.set('clipPathObject', obj.clipPathObject);
          // options.target.set('clipPathJson', obj.clipPathJson);
          // options.target.set('clipPathSVG', obj.clipPathSVG);
        }
        listOfIntersect.push(obj); // out if
      
      } else {
        // mouse não entrou na área, mas ele já tem um clipPath, entrão mantem na lista
        if(options.target.clipPath !== undefined){
          listOfIntersect.push(obj); // out if
        }
      }

    });

    // if the object move out original ClipPath remove clipPath
    if (options.target.hasOwnProperty('clipPathTreeIndex')){
      if (options.target.clipPathTreeIndex > -1) {
        const item = c.item(options.target.clipPathTreeIndex);
        if(item){
          if (!options.target.intersectsWithObject(item)) {
            options.target.set('clipPath', undefined);
            // INSERIR DENTRO do object.artboard:::
            options.target.set('clipPathKey', null);
            options.target.set('clipPathTreeOwnerKey', '');
            options.target.set('clipPathTreeIndex', null);
            options.target.set('clipPathOnlyOver', undefined); // NAO EH USADO AINDA, para objetos sobre o artboard mas outsides
            // backup
            // options.target.set('clipPathObject', null);
            // options.target.set('clipPathJson', null);
            // options.target.set('clipPathSVG', null);
          }  
        }
      }  
    }

    // if out intersect remove ClipPath
    if(listOfIntersect.length === 0){

      // objects has clipPath but not a Artboard. Not apply artboard
      if (options.target.clipPath && !options.target.clipPathKey) return;

      options.target.set('clipPath', undefined);
      // INSERIR DENTRO do object.artboard:::
      options.target.set('clipPathKey', null);
      options.target.set('clipPathTreeOwnerKey', '');
      options.target.set('clipPathTreeIndex', null);
      options.target.set('clipPathOnlyOver', undefined); // NAO EH USADO AINDA, para objetos sobre o artboard mas outsides
      // backup
      // options.target.set('clipPathObject', null);
      // options.target.set('clipPathJson', null);
      // options.target.set('clipPathSVG', null);
    }

    let listOutside = [];  
    let listInside = [];

    // create a tree
    c.forEachObject(function(obj,indx) {

        if (obj.clipPathTreeOwnerKey && obj.typeName !== 'Artboard'){ // && !obj.clipPathObject

          let item = {};
          let indexOdParent = listInside.map(function(e) { return e[properties.key]; }).indexOf( obj.clipPathTreeOwnerKey );
          
          // if parent not exists
          if( indexOdParent === -1 ) {
            item = {
              [properties.index]: -1, 
              [properties.key]: obj.clipPathTreeOwnerKey,
              [properties.parentKey]: null,
              [properties.label]: `Artboard`, // (${indx})
              [properties.children]: [],
            };
            indexOdParent = listInside.length;
            listInside.push(item);
          } 

          listInside[indexOdParent][properties.children].push({
            [properties.index]: indx, 
            [properties.key]: obj.key,
            [properties.parentKey]: obj.clipPathTreeOwnerKey ? obj.clipPathTreeOwnerKey : null ,
            [properties.label]: obj.typeName ? obj.typeName : 'Object' ,
            [properties.children]:[],
          });
        
        } else if (!obj.clipPathTreeOwnerKey && obj.typeName === 'Artboard'){ // && obj.clipPathObject

          listInside.push({
            [properties.index]: indx, 
            [properties.key]: obj.key,
            [properties.parentKey]: null,
            [properties.label]: `Artboard`, //${indx}
            [properties.children]: [],
          });

        } else {

          // if(obj.typeName !== 'ArtboardClipPath'){
            listOutside.push({
              [properties.index]: indx, 
              [properties.key]: obj.key,
              [properties.parentKey]: null,
              [properties.label]: obj.typeName ? obj.typeName : 'Object' ,
              [properties.children]: [],
            });  
          // }

        }

      });

    // clone
    const tree = [...listInside].reverse();

    // remove duplicate Parents Keys
    listInside = [...new Map(tree.map(item => [item[properties.key], item])).values()];

    /*
    const keys = ['key'];
    const filtered = listInside.filter(
      (s => o => 
        (k => !s.has(k) && s.add(k))
          (keys.map(k => o[k]).join('|'))
        )
        (new Set)
      );
    */
    
    // console.log('listInside',listInside);  
    // console.log('listOutside',listOutside);

    // const updateTree = (prev) => {
    //   return {
    //     ...prev,
    //     layersTree: {
    //       listInside: listInside,
    //       listOutside: listOutside,
    //     }
    //   }
    // };

    // const {setGlobal} = this.context;
    // setGlobal(updateTree);
    // this.props.setGlobal(updateTree);
    // this.canvasToGlobal();

    // tree
    c.layersTree = {
      listInside,
      listOutside,
    };

  };
  
  //------------------------------------------------------------------------------------------------
  // Artboard
  //------------------------------------------------------------------------------------------------
  drawArtboad = (c) => {

    if(c.isDrawArtboard) return;

    const removeDrawEvents = () => {
      c.off("mouse:down", mouseDown);
      c.off("mouse:up", mouseUp);
      c.off("mouse:move", mouseMove);
    };
  
    //removeDrawEvents();
    
    UtilsObject.enableSelectable(c, false);

    let rect, title, isDown, origX, origY;

    c.isDrawArtboard = true;

    const mouseDown = (o) => {

      console.log('on("mouse:down")');
      UtilsCanvas.enableSelection(c, false);

      isDown = true;
      let pointer = c.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;

      title = new fabric.Text('hello world', {

        fontFamily: 'helvetica',
        fontSize: 12,

        key: UtilsCanvas.key(),
        typeName: "ArtboardTitle",
  
        left: parseFloat(origX.toFixed(1)),
        top: parseFloat(origY.toFixed(1)) - 18,
        originX: "left",
        originY: "top",

        fill: 'grey',

        editable: false,
        hasBorders: false,
        hasControls: false,

        selectable: false,
        hasRotatingPoint: false,
        evented: true,
  
        lockScalingX: true,
        lockScalingY: true,
        lockMovementX: false,
        lockMovementY: false,
        lockRotation: true,

        isLockForDraw: true,

        transparentCorners: false,

        _controlsVisibility: {tl:false,tr:false,br:false,bl:false,ml:false,mt:false,mr:false,mb:false,mtr:false},

      });

      // ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb', 'mtr'].forEach(el => {
      //   title.setControlVisible(el, false);
      // })
      // faster
      //title._controlsVisibility = {tl:false,tr:false,br:false,bl:false,ml:false,mt:false,mr:false,mb:false,mtr:false};

      c.add(title);

      rect = new fabric.Rect({

        key: UtilsCanvas.key(),
        typeName: "Artboard",

        isAMask: true,
  
        left: parseFloat(origX.toFixed(1)),
        top: parseFloat(origY.toFixed(1)),
        originX: "left",
        originY: "top",
        rx: 0,
        ry: 0,
        width: parseFloat(parseFloat(pointer.x - origX).toFixed(1)),
        height: parseFloat(parseFloat(pointer.y - origY).toFixed(1)),
        angle: 0,
        fill: '#ffffff',
        //stroke: STROKE_COLOR,
        strokeWidth: 0, // STROKE_WIDTH,
        strokeUniform: true,
        //transparentCorners: false,
        selectable: false,
        objectCaching: false,

        // shadow: {
        //   color: '#AAAAAA',
        //   blur: 3,
        //   offsetX: 2,
        //   offsetY: 2,
        //   nonScaling: true,
        // }
      });

      c.add(rect);
      UtilsCanvas.renderAll(c);
    }

    c.on("mouse:down", mouseDown);

    const mouseMove = (o) => {

      console.log('on("mouse:move")');
      if (!isDown) return;
      let pointer = c.getPointer(o.e);

      pointer.x = pointer.x < 0 ? 0 : pointer.x;
      pointer.y = pointer.y < 0 ? 0 : pointer.y;

      if (origX > pointer.x) {
        rect.set({
          left: parseFloat(Math.abs(pointer.x).toFixed(1)),
        });
      }
      if (origY > pointer.y) {
        rect.set({
          top: parseFloat(Math.abs(pointer.y).toFixed(1)),
        });
      }

      rect.set({
        width: parseFloat(Math.abs(origX - pointer.x).toFixed(1)),
        height: parseFloat(Math.abs(origY - pointer.y).toFixed(1)),
      });

      UtilsCanvas.renderAll(c);
    }

    c.on("mouse:move", mouseMove);

    const mouseUp = (o) => {

      console.log('on("mouse:up")');
      removeDrawEvents();
      UtilsCanvas.enableSelection(c, true);
      UtilsObject.enableSelectable(c, true);
      isDown = false;
      rect.setCoords();
      // UtilsCanvas.setActiveObject( c, rect );
      UtilsCanvas.renderAll(c);

      const rectMaskHide = new fabric.Rect({

        ownCaching: false,
        objectCaching: false,
        dirty: true,
        perPixelTargetFind: true, // ?? http://fabricjs.com/docs/fabric.Object.html#perPixelTargetFind

        key: UtilsCanvas.key(),
        typeName: 'ArtboardClipPath',
        absolutePositioned: true, // options only clipPath
        fixed: true,

        // top: rect.top + STROKE_WIDTH,
        // left: rect.left + STROKE_WIDTH,
        // width: rect.width - (2 * STROKE_WIDTH),
        // height: rect.height - (2 * STROKE_WIDTH),

        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,

        angle: rect.angle,
        scaleX: rect.scaleX,
        scaleY: rect.scaleY,

        fill: null, // 'transparent',
        stroke: 'red',
        strokeWidth: 0,
        strokeUniform: true,
        
        isLockForDraw: true,

        editable: false,
        transparentCorners: false,
        hasBorders: false,

        selectable: false,
        hasControls: false,
        hasRotatingPoint: false,
        evented: false,
  
      });

      c.add(rectMaskHide); // ???
      c.sendToBack(rectMaskHide); // ???

      // console.log(rectMaskHide.toSVG());
      // console.log(rectMaskHide.toSVG({suppressPreamble: true}));
      // console.log(rectMaskHide.toSVG({encoding: 'ISO-8859-1'}));
      // .toSVG({
      //   viewBox: {
      //       x: 80,
      //       y: 80,
      //       width: 250,
      //       height: 250
      //   }
      // });
      
      rect.set({

        ownCaching: false,
        objectCaching: false,
        dirty: true,

        clipPathKey: rectMaskHide.key,
        absolutePositioned: true, // options only clipPath
        fixed: true,

        // PROBLEMA
        // clipPathObject: rectMaskHide, // AQUI ARMAZENA A MASCARA, porem DEPOIS QUE IMPORTA O JSON DA UM SERIO PROBLEMA
        // clipPathJson: JSON.stringify(rectMaskHide),
        // clipPathSVG: String(rectMaskHide.toSVG()), // data:image/svg+xml;utf8,
      
        selectable: false,
        hasControls: false,
        evented: true,
  
        lockScalingX: true,
        lockScalingY: true,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,

      });

      c.sendToBack(rect);
      c.bringToFront(title);

      // ######################################################
      // ESSA FUNCAO SE PERDE NA EXPORTACAOOO
      // criar um loop para depois da importação aplicar funcoes nos objetos e no canvas
      // ######################################################
      title.on('mousemove', function(){
        rect.set({
          top: this.top + 18,
          left: this.left,
        });
        rectMaskHide.set({
          top: this.top + 18,
          left: this.left,
        });
        this.clipPath = undefined;
      });
      
      // ######################################################
      // ESSA FUNCAO SE PERDE NA EXPORTACAOOO
      // criar um loop para depois da importação aplicar funcoes nos objetos e no canvas
      // ######################################################
      title.on('mouseup', function(){
        // apos mover o artboard o clipPath em si não se move, é preciso atualizar as coordenadas
        //c.forEachObject( el => el.setCoords() ); // update coords
        rect.setCoords();
        rectMaskHide.setCoords();
        c.renderAll();
      });

      // ######################################################
      // ESSA FUNCAO SE PERDE NA EXPORTACAOOO
      // criar um loop para depois da importação aplicar funcoes nos objetos e no canvas
      // ######################################################
      rect.on('mouseover', function(){
        c.bringToFront(title);
      });

      // ######################################################
      // ESSA FUNCAO SE PERDE NA EXPORTACAOOO
      // criar um loop para depois da importação aplicar funcoes nos objetos e no canvas
      // ######################################################
      rect.on('mousedblclick', function(){

        const {width, height} = this;

        this.set({

          // PROBLEM !!!
          // selectable: !this.selectable,
          // hasControls: !this.hasControls,
          // evented: true,
    
          // PROBLEM !!!
          // lockScalingX: !this.lockScalingX,
          // lockScalingY: !this.lockScalingY,
          // lockMovementX: !this.lockMovementX,
          // lockMovementY: !this.lockMovementY,
          // lockRotation: false, // nao pode girar pois vai perder as dimensoes

          width: width + 20,
          height: height + 20,

        });

        rectMaskHide.set({
          // width: width + 20 - (2 * STROKE_WIDTH),
          // height: height + 20 - (2 * STROKE_WIDTH),
          width: width + 20,
          height: height + 20,
        });

        c.renderAll();

      });

      c.isDrawArtboard = false;

      fabric.Canvas.prototype.history.register();

    };

    c.on("mouse:up", mouseUp);

  };


  //------------------------------------------------------------------------------------------------
  // Add Rectangle
  //------------------------------------------------------------------------------------------------
  addArtboard = ( c, settings, callback ) => {

    if ( typeof settings !== 'object' ) return;

    const { 
      name, width, height, originX, originY, 
      selectable, hasControls, angle, opacity, scale, 
      index, fill, stroke, radius, strokeWidth, 
      top, left, relativePosition
    } = settings ;
    
    const shape = new fabric.Rect({
      key: UtilsCanvas.key(),
      typeName: "Rect",
      name: name || "",
      width: parseFloat( width || 100 , 1 ),
      height: parseFloat( height || 100 , 1 ),
      left: relativePosition === false ? left : UtilsCanvas.setPositionLeft( c, left )  ,
      top: relativePosition === false ? top : UtilsCanvas.setPositionTop( c, top ) ,
      originX: originX || "left",
      originY: originY || "top",
      scale: isNaN(scale) ? 0 : scale,
      rx: isNaN(radius) ? 0 : radius ,
      ry: isNaN(radius) ? 0 : radius ,
      angle: isNaN(angle) ? 0 : angle ,
      opacity: opacity || OPACITY ,
      fill: fill || FILL_COLOR,
      stroke: stroke || STROKE_COLOR,
      strokeWidth: strokeWidth || STROKE_WIDTH,
      strokeUniform: true,
      selectable: selectable === false ? false  : true ,
      hasControls: hasControls === false ? false : true ,
      objectCaching: false,
    });

    // shape.setPatternFill({
    //     source: imageLoaded,
    //     repeat: 'no-repeat',
    //     patternTransform: [0.2, 0, 0, 0.2, 0, 0]
    // });

    c.add( shape );

    // change index object before create and add on canvas
    if ( index !== undefined ) {
      shape.moveTo( index );
    }

    // set active object
    UtilsCanvas.setActiveObject( c, shape );
    // render all
    UtilsCanvas.renderAll( c );

    fabric.Canvas.prototype.history.register();

    if( callback !== undefined ){
      callback();
    }

  }

  // Pen
  // http://fabricjs.com/freedrawing

}

export default Artboard;
