import React from "react";
import { fabric } from "fabric";
import UtilsCanvas from './../../utils/UtilsCanvas';

class Scaling extends React.Component {

  state = {
    name: (this.props && this.props.name) || 'canvas',
  };

  constructor(props) {
    super(props);
    this.objects = this.objects.bind(this);
    this.fillGradient = this.fillGradient.bind(this);
  };

  fillGradient(object){
    if(object.fill){
      if(typeof object.fill === 'object'){
        if(object.fill.type === 'radial'){ //object.fill.type === 'linear'
 
          const {width, height} = object;
          const radius = (height/2) + width/4;
          const coords = {
            ...object.fill.coords,
            r1: radius,
            r2: width * 0.05,
            x1: width/2,
            y1: height/2,
            x2: width/2,
            y2: height/2,
          };
          object.fill.coords = coords;

        }
      }
    } 
  };

  objects(event){

    const c = fabric.Canvas.prototype.canvas(this.state.name);
    let object = event.target || UtilsCanvas.getActiveObject(c);

    if (object === undefined || object === null) return;
    if (object.hasOwnProperty("typeName") === false) {
      //throw new Error("O elemento selecionado não tem definição no sistema. This element not type definition (.typeName)");
    }
  
    // console.log("object:scaling:start", object.typeName, object.get('type'));
  
    // old values
    const { width:oldWidth, height:oldHeight, radius:oldRadius, rx:oldRx, ry:oldRy } = object;
    // new values
    let newWidth, newHeight, newRadius, newRx, newRy;
  
    switch (object.typeName) {
  
      case "Circle":
        c.on("object:scaled", (event) => {
  
          if(object.scaleX !== object.scaleY){
            newRadius = oldRadius;
          }else{
            newRadius = oldRadius * ( object.scaleX < object.scaleY ? object.scaleX : object.scaleY );
          }
  
          object.set({
            scaleX: 1,
            scaleY: 1,
            radius: Number(newRadius.toFixed(2)),
          });

          object.setCoords();    
          this.fillGradient(object);
          c.renderAll();

          // console.log("object:scaled:end");
          c.off("object:scaled");
        });
  
        break;
      case "Ellipse":
        c.on("object:scaled", (event) => {
  
          newRx = oldRx * object.scaleX;
          newRy = oldRy * object.scaleY;
  
          object.set({
            rx: Number(newRx.toFixed(2)),
            ry: Number(newRy.toFixed(2)),
            scaleX: 1,
            scaleY: 1,
          });
  
          object.setCoords();    
          this.fillGradient(object);
          c.renderAll();

          // console.log("object:scaled:end");
          c.off("object:scaled");
        });
  
        break;
      case "Background":
      case "Rect":
      case "Square":
      case "Line":
        c.on("object:scaled", (event) => {
  
          newWidth = oldWidth * object.scaleX;
          newHeight = oldHeight * object.scaleY;
          newRx = oldRx * object.scaleX;
          newRy = oldRy * object.scaleY;
          newRadius = ( newRy + newRx ) / 2 ;
  
          object.set({
            rx: Number(newRadius.toFixed(2)),
            ry: Number(newRadius.toFixed(2)),
            width: Number(newWidth.toFixed(2)),
            height: Number(newHeight.toFixed(2)),
            scaleX: 1,
            scaleY: 1,
          });
  
          object.setCoords();    
          this.fillGradient(object);
          c.renderAll();

          // console.log("object:scaled:end");
          c.off("object:scaled");
        });
  
        break;
      case "Label":
      case "Text":
      case "TextBox":
      case "Paragraph":
        c.on("object:scaled", (event) => {
  
          newWidth = oldWidth * object.scaleX;
          newHeight = oldHeight * object.scaleY;
  
          object.set({
            width: Number(newWidth.toFixed(2)),
            height: Number(newHeight.toFixed(2)),
          });
  
          if(object.hasOwnProperty('fontSize')){
            console.log('(1)fontSize:',object.fontSize);
            object.fontSize *= object.scaleX;
            object.fontSize = Number(object.fontSize).toFixed(1);
            object.scaleX = 1;
            object.scaleY = 1;
            /* _clearCache clear the two caching arrays for lineWidths and lineHeights 
            ( we do not recalculate them every time in fabricjs) i do not know why i 
            force the call since a change in fontsize trigger the cleaning automatically */
            object._clearCache();
            object.setCoords();
            console.log('(2)fontSize:',object.fontSize);
          }

          object.setCoords();    
          this.fillGradient(object);
          c.renderAll();
  
          // console.log("object:scaled:end");
          c.off("object:scaled");
        });
        break;
      default:
        if(object.hasOwnProperty('typeName')===false){
          let type = object.get('type');
          type = type.charAt(0).toUpperCase() + type.slice(1);
          object.set({typeName: type});  
        }
        break;
    }
  
    console.log("object:scaling:end");
  }

}

export default Scaling;