import { fabric } from 'fabric';
import Images from './../Images';

function objectsEqual(o1, o2) {
  
  const entries1 = Object.entries(o1);
  const entries2 = Object.entries(o2);

  if (entries1.length !== entries2.length) {
    return false;
  }
  
  for (let i = 0; i < entries1.length; ++i) {
    // Keys
    // if (entries1[i][0] !== entries2[i][0]) {
    //   return false;
    // }
    // Values
    if (entries1[i][1] !== entries2[i][1]) {
      return false;
    }
  }

  return true;
}

function normalizeAlphaShadow(shadow, alpha) {

  console.log('inner-shadow:normalize');

  const imageData = shadow.getContext("2d").getImageData(0, 0, shadow.width, shadow.height);
  const pixelData = imageData.data;
  let i,len = pixelData.length;
  let max = 0;

  for(i=3; i<len; i+=4) if(pixelData[i]>max) max = pixelData[i]; max = (255/max) * alpha;
  for(i=3; i<len; i+=4) pixelData[i] *= max;      
  shadow.getContext("2d").putImageData(imageData, 0, 0);
  return shadow;
}

function _renderImage(ctx) {

  // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/filter
  // https://www.viget.com/articles/instagram-style-filters-in-html5-canvas/
  // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip
  // const canvas = document.getElementById('canvas');
  // const ctx = canvas.getContext('2d');

  // ADVANCED
  // https://www.html5rocks.com/en/tutorials/canvas/texteffects/
  
  // // Create circular clipping region
  if(this.typeName === 'ImageAdvanced'){

    ctx.beginPath();
    ctx.arc(10, 10, 100, 0, Math.PI * 2);
    //ctx.rect(0, 0, this.width, this.height);
    ctx.rect(20, 20, 150, 100);
    // ctx.fill();
    ctx.clip();
    // The clearRect() method is used to clear a rectangle (erase the area to transparent black).   
    // clearRect()

    

    // NC
    // this._element.perPixelTargetFind = true;
    // this.perPixelTargetFind = true;

    // // Draw stuff that gets clipped
    // ctx.fillStyle = 'blue';
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
    // ctx.fillStyle = 'orange';
    // ctx.fillRect(0, 0, 100, 100);
  }

    ctx.fillStyle = 'orange';
    ctx.fillRect(-150, -150, 400, 400);


    // draw in image to main canvas
    // ctx.drawImage(this, 0, 0);

    // // invert alpha channel
    // ctx.globalCompositeOperation = "xor";
    // ctx.fillRect(0, 0, c.width, c.height);

    // // draw itself again using drop-shadow filter
    // ctx.shadowBlur = 7*2;  // use double of what is in CSS filter (Chrome x4)
    // ctx.shadowOffsetX = ctx.shadowOffsetY = 5;
    // ctx.shadowColor = "#000";
    // ctx.drawImage(c, 0, 0);

    // // draw original image with background mixed on top
    // ctx.globalCompositeOperation = "destination-atop";
    // ctx.shadowColor = "transparent";                  // remove shadow !
    // ctx.drawImage(this, 0, 0);


  // custom clip code
  if (this.clipPath) {
    // ctx.save();
    // if (this.clipPath.fixed || this.clipPath.absolutePositioned) {
    //   var retina = this.canvas.getRetinaScaling();
    //   ctx.setTransform(retina, 0, 0, retina, 0, 0);
    //   // to handle zoom
    //   ctx.transform.apply(ctx, this.canvas.viewportTransform);
    //   //
    //   this.clipPath.transform(ctx);
    // }
    // this.clipPath._render(ctx);
    // ctx.restore();
    // ctx.clip();
  }
  // end custom clip code
    
  // var x = -this.width / 2, y = -this.height / 2, elementToDraw;
  // if (this.isMoving === false && this.resizeFilter && this._needsResize()) {
  //   this._lastScaleX = this.scaleX;
  //   this._lastScaleY = this.scaleY;
  //   this.applyResizeFilters();
  // }
  // elementToDraw = this._element;
  
  // elementToDraw && ctx.drawImage(elementToDraw,
  //   0, 0, this.width, this.height,
  //   x, y, this.width, this.height);
                
  // this._stroke(ctx);
  // this._renderStroke(ctx);

  // copy fabricjs original
  fabric.util.setImageSmoothing(ctx, this.imageSmoothing);
  if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
    this.applyResizeFilters();
  }
  this._stroke(ctx);
  this._renderPaintInOrder(ctx);

  ctx.fillStyle = '#FF00FF44';
  ctx.fillRect(-150, -150, 380, 380);

};

function _renderImagesInnerShadow (ctx) {

  this.objectCaching = true;
  this.dirty = false;
  
  // copy fabricjs original
  fabric.util.setImageSmoothing(ctx, this.imageSmoothing);
  if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
    this.applyResizeFilters();
  }
  this._stroke(ctx);
  this._renderPaintInOrder(ctx);

  // Inner Shadow

  // this.innerShadow && console.log('compare',objectsEqual(this.innerShadow, this.innerShadowBack), this.innerShadow, this.innerShadowBack);
  // confere if shadow change
  const changeInnerShadow = this.innerShadow && this.innerShadowBack ? objectsEqual(this.innerShadow, this.innerShadowBack) : false;

  if(!changeInnerShadow){ // || !this.innerShadowElement) {

    console.log('inner-shadow:change');

    // if(this === this.canvas.getActiveObject() || !this.innerShadowElement) {
    // if(this === this) {

    //console.log('hasInnerShadow');

    this.innerShadow || (this.innerShadow = {
      offsetX: 0,
      offsetY: 0,
      blur: 10,
      alpha: 0.5,
      color: 'black', // string, hex, rgba(0,0,0,0.3) 
    })

    var offsetX   = this.innerShadow.offsetX || 0;
    var offsetY   = this.innerShadow.offsetY || 0;
    var blur      = this.innerShadow.blur || 10;
    var alpha     = this.innerShadow.alpha || 0.5;
    var color     = this.innerShadow.color || 'rgba(0,0,0,1)';

    // back
    this.innerShadowBack = {...this.innerShadow};

    // base
    var offset = 50 + blur;

    // the size of the shadow depends on the size of the target,
    // then I will create extra "walls" around the picture to be sure
    // tbat the shadow will be correctly filled (with the same intensity everywhere)
    // (it's not obvious with this image, but it is when there is no space at all between the image and its border)
    var hole = document.createElement("canvas");
    var holeContext = hole.getContext("2d");

    // w h 
    hole.width = this._element.width + (offset * 2);
    hole.height = this._element.height + (offset * 2);

    // first, I draw a big black rect
    holeContext.fillStyle = "#000000";
    holeContext.fillRect(0, 0, hole.width, hole.height);
    
    // then I use the image to make an hole in it
    holeContext.globalCompositeOperation = "destination-out";
    holeContext.drawImage(this._element, offset, offset);
    
    // I create a new canvas that will contains the shadow of the hole only
    var shadow = document.createElement("canvas");
    var shadowContext = shadow.getContext("2d");

    // w h 
    shadow.width = this._element.width;
    shadow.height = this._element.height;

    // Filter - Opption 1
    shadowContext.filter = `drop-shadow(${offsetX}px ${offsetY}px ${blur}px ${color}) `;

    // Filter - Option 2
    // shadowContext.shadowBlur = blur*2;  // use double of what is in CSS filter (Chrome x4)
    // shadowContext.shadowOffsetX = offsetX;
    // shadowContext.shadowOffsetY = offsetY;
    // shadowContext.shadowColor = color;

    shadowContext.drawImage(hole,-offset,-offset);
    shadowContext.globalCompositeOperation = "destination-out";
    shadowContext.drawImage(hole,-offset,-offset);
    
    // now, because the default-shadow filter is really to soft, I normalize the shadow 
    // then I will be sure that the alpha-gradient of the shadow will start at "shadowAlpha" and end at 0
    alpha && (shadow = normalizeAlphaShadow(shadow, alpha));
    
    ctx.drawImage(shadow,
      this._element.width/2 * -1,
      this._element.height/2 * -1,
    );

    // back
    //console.log(shadow);
    this.innerShadowElement = shadow;
    //this.innerShadowElementDataURL = shadow.toDataURL();

    // after LOAD JSON
    // var canvas = document.createElement("canvas");
    // var ctx = canvas.getContext("2d");
    // var image = new Image();
    // image.onload = function() {
    //   ctx.drawImage(image, 0, 0);
    // };
    // image.src = "data:image/png;base64,iVBORw0KGgo...";

    // ----------------------------------------------------------------------------------------
    // // now send to fabric js
    // this.haveadd || (this.haveadd = 0)
    // if(this.haveadd < 1){
    //   this.haveadd += 1;
    //   var imgInstance = new fabric.Image(shadow, {
    //     // left: object.getBoundingRect().left,
    //     // top: object.getBoundingRect().top,
    //   });
    //   this.canvas.add(imgInstance);  
    // }
    // ----------------------------------------------------------------------------------------
    
  } else if(this.innerShadowElement) {

    console.log('inner-shadow:have-inner-shadow-element');

    ctx.drawImage(this.innerShadowElement,
      this._element.width/2 * -1,
      this._element.height/2 * -1,
    );
    
  } else if(this.innerShadowElementDataURL) {

    console.log('inner-shadow:have-inner-shadow-DataURL');

    // ctx.drawImage(this.innerShadowElementDataURL,
    //   this._element.width/2 * -1,
    //   this._element.height/2 * -1,
    // );  

    // after LOAD JSON
    var image = new Image();
    image.onload = function() {
      this.innerShadowElement = image;
      ctx.drawImage(image, 0, 0);
    };
    image.src = this.innerShadowElementDataURL;
    // window.alert(this.innerShadowElementDataURL);

  }

}

function _renderShapesInnerShadow (ctx, a) {
  
  // 1x1 case (used in spray brush) optimization was removed because
  // with caching and higher zoom level this makes more damage than help

  var rx = this.rx ? Math.min(this.rx, this.width / 2) : 0,
      ry = this.ry ? Math.min(this.ry, this.height / 2) : 0,
      w = this.width,
      h = this.height,
      x = -this.width / 2,
      y = -this.height / 2,
      isRounded = rx !== 0 || ry !== 0,
      /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
      k = 1 - 0.5522847498;
  ctx.beginPath();

  ctx.moveTo(x + rx, y);

  ctx.lineTo(x + w - rx, y);
  isRounded && ctx.bezierCurveTo(x + w - k * rx, y, x + w, y + k * ry, x + w, y + ry);

  ctx.lineTo(x + w, y + h - ry);
  isRounded && ctx.bezierCurveTo(x + w, y + h - k * ry, x + w - k * rx, y + h, x + w - rx, y + h);

  ctx.lineTo(x + rx, y + h);
  isRounded && ctx.bezierCurveTo(x + k * rx, y + h, x, y + h - k * ry, x, y + h - ry);

  ctx.lineTo(x, y + ry);
  isRounded && ctx.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y);

  ctx.closePath();

  this.ctxBack || (this.ctxBack = ctx);

  this._renderPaintInOrder(ctx);

  // Inner Shadow

  this.on({
    'event:rotating': () => console.log('rot'),
    'event:scaling': () => console.log('sca'),
    'event:moving': () => console.log('mov'),
    'event:skewing': () => console.log('ske'),
  })

  if(this.isMoving) console.log('isMoving');
  if(this.isTra) console.log('isMoving');

  console.log('####################################', this.ctxBack);
  
  this.loopppp || (this.loopppp = 0);
  this.loopppp += 1;
  
  console.log('>>>>>>>>>>>>>>>>>>>>>>>>', this.loopppp);

  if(
    !this.blckkk || 
    this.loopppp > 5 || 
    this.blckkkWidth !== this.width ||
    this.blckkkH !== this.height
  ){

    this.blckkk = true;
    this.loopppp = 0;
    this.blckkkWidth = this.width;
    this.blckkkH = this.height;

    const base64 = this.toDataURL({
      // format
      format: 'png', // png | jpg
      quality: 1, // only jpg
      // scale
      multiplier: 1, // scale, default 1
    });

    this.base64 = base64;
    console.log(base64);


  this.innerShadow || (this.innerShadow = {
    offsetX: 0,
    offsetY: 0,
    blur: 10,
    alpha: 0.5,
    color: 'black', // string, hex, rgba(0,0,0,0.3) 
  })

  var offsetX   = this.innerShadow.offsetX || 0;
  var offsetY   = this.innerShadow.offsetY || 0;
  var blur      = this.innerShadow.blur || 10;
  var alpha     = this.innerShadow.alpha || 0.5;
  var color     = this.innerShadow.color || 'rgba(0,0,0,1)';

  // back
  this.innerShadowBack = {...this.innerShadow};

  // base
  var offset = 50 + blur;

  // the size of the shadow depends on the size of the target,
  // then I will create extra "walls" around the picture to be sure
  // tbat the shadow will be correctly filled (with the same intensity everywhere)
  // (it's not obvious with this image, but it is when there is no space at all between the image and its border)
  var hole = document.createElement("canvas");
  var holeContext = hole.getContext("2d");

  // w h 
  hole.width = this.width + (offset * 2);
  hole.height = this.height + (offset * 2);

  // first, I draw a big black rect
  holeContext.fillStyle = "#000000";
  holeContext.fillRect(0, 0, hole.width, hole.height);
  // then I use the image to make an hole in it
  holeContext.globalCompositeOperation = "destination-out";

  // opt 1
  // const image = document.createElement('img');
  // image.src = base64;
  // image.addEventListener('load', e => {
  //   ctx.drawImage(image, 33, 71, 104, 124, 21, 20, 87, 104);
  // });

  // opt 2
  // const image = document.createElement('img');
  // image.src = base64;
  // image.onload = function(){
  //   ctx.drawImage(image,0,0);
  // }

  // opt 3
  // let image = ctx.getImageData(60, 60, 200, 100);
  // ctx.putImageData(image, 150, 10);

  console.log('@@@@@@@@@@', this.base64, ctx.canvas );

  // opt 4
  var image = new Image();
  image.src = this.base64;
  image.onload = () => {

    const w = this.width * (this.scaleX || 1);
    const h = this.height * (this.scaleY || 1);

    console.log('@@@@@@@@@@@@@@@ base64 loaded', this.width, this.scaleX, this, ctx);

    holeContext.drawImage(image, offset, offset);
  
    // I create a new canvas that will contains the shadow of the hole only
    var shadow = document.createElement("canvas");
    var shadowContext = shadow.getContext("2d");

    // w h 
    shadow.width = w;
    shadow.height = h;

    // Filter - Opption 1
    shadowContext.filter = `drop-shadow(${offsetX}px ${offsetY}px ${blur}px ${color}) `;

    // Filter - Option 2
    // shadowContext.shadowBlur = blur*2;  // use double of what is in CSS filter (Chrome x4)
    // shadowContext.shadowOffsetX = offsetX;
    // shadowContext.shadowOffsetY = offsetY;
    // shadowContext.shadowColor = color;

    shadowContext.drawImage(hole,-offset,-offset);
    shadowContext.globalCompositeOperation = "destination-out";
    shadowContext.drawImage(hole,-offset,-offset);
    
    // now, because the default-shadow filter is really to soft, I normalize the shadow 
    // then I will be sure that the alpha-gradient of the shadow will start at "shadowAlpha" and end at 0
    alpha && (shadow = normalizeAlphaShadow(shadow, alpha));
    
    this.shadowK = shadow;
    ctx.drawImage(shadow,
      w/2 * -1,
      h/2 * -1,
    );

    // back
    //console.log(shadow);
    this.innerShadowElement = shadow;
  }

  this.shadowK && ctx.drawImage(this.shadowK,
    -100,
    -100,
  );


    // const base64 = this.toDataURL({
    //   // format
    //   format: 'png', // png | jpg
    //   quality: 1, // only jpg
    //   // scale
    //   multiplier: 1, // scale, default 1
    // });

    // this.base64 = base64;

  }

  // this.objectCaching = true;
  // this.dirty = false;

}

export {
  objectsEqual,
  normalizeAlphaShadow,
  _renderImage,
  _renderImagesInnerShadow,
  _renderShapesInnerShadow,
} 