const properties = [
  // personal
  "_controlsVisibility", // ???
  "isFabricCanvas",
  "typeName",
  "key",
  "name",
  "responsive", // to elements like shadow, follow other elements dynamically
  //"controls",

  // start artboard
  "artboard",
  "clipPath", // 
  "clipPathKey", // key clipPath (mask)
  "clipPathTreeOwnerKey", // tree ownerkey
  "clipPathTreeIndex", // tree index
  // end artboard

  // innershadow
  'innerShadow',

  // radius
  "rx",
  "ry",
  // font
  "fonts",
  "fontFileName",
  "fontFileSize",
  // document, pattern, pallete, fill & stroke
  "document", // to avenuz report, tecnical, ficha tecnica 
  // "pattern",
  "filters",
  // fabricjs
  "evented",
  "hasControl",
  "hasBorders",
  "hasRotatingPoint",
  "selectable",
  "objectCaching",
  "preserveObjectStacking",
  "absolutePositioned",
  // lock
  "lockMovementX",
  "lockMovementY",
  "lockRotation",
  "lockScalingX",
  "lockScalingY",
  "lockUniScaling",
  "hoverCursor",
  // allow images
  "crossOrigin",
];
export default properties;