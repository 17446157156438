import React, { createContext, useContext, useState } from "react";

export const ArtboardContext = createContext();
export default function ArtboardProvider({ children }) {

  const [getArtboard, setArtboard] = useState({
  });

  return (
    <ArtboardContext.Provider value={{ getArtboard, setArtboard }}>
      {children}
    </ArtboardContext.Provider>
  );

}

// created a personal hook
// this call context inside file
export function useArtboard() {
  const context = useContext( ArtboardContext );
  //if(!context) throw new Error('useGlobal need privider. useGlobal must be used within a GlobalProvider');
  const { getArtboard, setArtboard } = context;
  return { getArtboard, setArtboard };
}
