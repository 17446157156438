import React from "react";
import { fabric } from "fabric";
import UtilsCanvas from './../../utils/UtilsCanvas';

class Type extends React.Component {

  state = {
    name: (this.props && this.props.name) || 'canvas',
  };

  constructor(props) {
    super(props);
    this.objects = this.objects.bind(this);
  };

  objects = (c, event) => {

    c || (c = fabric.Canvas.prototype.canvas(this.state.name));

    let object = event.target || UtilsCanvas.getActiveObject(c);
    
    if (!object) return;
  
    switch (object.typeName) {

      case "RectResize":
      case "rectResize":
        object.typeName = 'RectResize';
        UtilsCanvas.sendToBackRectResize(c, object, true);
        break;
      case "RectMP":
        UtilsCanvas.sendToBackRectMP(c, object, true);
        break;
      case "Artboard":
      case "ArtboardClipPath":
        c.sendToBack(object);
        console.log('AS ACOES ON SE PERDEM DEPOIS DA IMPORTACAO JSON');
        break;
      case "ArtboardTitle":
        c.bringToFront(object);
        console.log('CRIAR ACAO PARA MOVER ARTBOARD DEPOIS DA IMPORTACAO JSON');
        // window.alert('CRIAR ACAO PARA MOVER ARTBOARD DEPOIS DA IMPORTACAO JSON');
        break;

      case "Group":
      case "SVG":
      case "Path":
        console.log('does nothing (Group|SVG)');
        break;
      case "Image":
        console.log('does nothing (Image)');
        break;
      case "Ellipse":
      case "Line":
      case "Circle":
      case "Square":
      case "Rect":
        console.log('does nothing (Shapes)');
        break;
      case "Label":
      case "Text":
      case "TextBox":
      case "Paragraph":
        console.log('does nothing (Text)');
        break;
      default:
        console.log(object.get('type'));
        if(object.hasOwnProperty('typeName')===false){
          let type = object.get('type');
          type = type.charAt(0).toUpperCase() + type.slice(1);
          object.set({typeName: type});  
        }
    }

    return object;

  }

}

export default Type;