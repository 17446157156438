import React from "react";
import { fabric } from "fabric";
// import UtilsCanvas from "../utils/UtilsCanvas";
// import GlobalEvents from "../event/GlobalEvents";

export default class Imports extends React.Component {

  fromJson = ( c, json, register ) => {

    // reset history
    register = register === undefined ? true : register; 
    if( register === true ){
      // GlobalEvents.dispatch('resetRegister', c );      
    }

    //UtilsCanvas.clear(c);
    let fonts = [];
    let sc = new fabric.Canvas();

    // Error: "TypeError: path.shouldCache is not a function"
    // Resolve problem clipPath
    // https://github.com/fabricjs/fabric.js/issues/5266
    if(typeof json === 'string') json = JSON.parse(json);
    json.objects.forEach(object => {
      if (object.clipPath) {
        fabric.util.enlivenObjects([object.clipPath], function(arg1) {
          object.clipPath = arg1[0];
        });
      }
    });

    // sc.clear();
    // sc.dispose();
    sc.loadFromJSON(
      json,
      // canvas.renderAll.bind(canvas),
      (event) => {
        console.log(fonts);
        // TODO librariesFont.multiLoad(fonts, (event) => {
        this.final(c, json, register);
        // });
      },
      (o, object) => {
        // `o` = json object
        // `object` = fabric.Object instance
        console.log(o,object);
        return;
        // if (object.hasOwnProperty("fontFileName") === true) {
        //   const { fontFamily, fontFileName, fontFileSize } = object;
        //   fonts.push({
        //     fontFamily: fontFamily,
        //     fontFileName: fontFileName,
        //     fontFileSize: fontFileSize,
        //   });
        // }
      }
    );
  };

  final = ( c, j, r ) => {
    //const uc = new UtilsCanvas();
    console.log("Load JSon");

    // Error: "TypeError: path.shouldCache is not a function"
    // Resolve problem clipPath
    // https://github.com/fabricjs/fabric.js/issues/5266
    if(typeof j === 'string') j = JSON.parse(j);
    j.objects.forEach(object => {
      if (object.clipPath) {
        fabric.util.enlivenObjects([object.clipPath], function(arg1) {
            object.clipPath = arg1[0];
        });
      }
    });

    // c.clear();
    // c.dispose();
    c.loadFromJSON(
      j, // json
      (event) => {
        // register
        if( r === true){
          fabric.Canvas.prototype.history.register();
        }
        // render
        c.renderAll.bind( c );
        c.requestRenderAll();
        // center content
				//uc.center( c );
        // zoom 1:1
        c.setViewportTransform([1, 0, 0, 1, 0, 0]);
        // TODO if (librariesHistory.state.history.value.length === 0) {
        // TODO 	librariesHistory.state.save();
        // TODO }
      },
      (o, object) => {
        // librariesFont.preInitializeFontsFromImport(object);
        console.log(o,object);
        // `o` = json object
        // `object` = fabric.Object instance
        // ... do some stuff ...
        object.dirty = true;  
        if(object.clipPath){
          object.clipPath = fabric.util.object.clone(object.clipPath);
        }
      }
    );
  };

}

