import React from "react";
import { fabric } from "fabric"; 
import UtilsCanvas from "../../utils/UtilsCanvas";
import properties from "./properties";
import Artboard from "./Artboard";

class Register extends React.Component {

  state = {
    name: (this.props && this.props.name) || 'canvas',
    properties: properties,
    times: this.times || 50,
    timeOut: this.timeOut || 500,
    addEvents: false,
    artboard: new Artboard(),
  };

  constructor(props) {
    super(props);
    fabric.Canvas.prototype.history = {
      init: this.init,
      keysDown: this.keysDown,
      reset: this.reset,
      lock: this.lock,
      unlock: this.unlock,
      register: this.register,
      undo: this.undo,
      redo: this.redo,
    };
  };

  init = () => {

    // propries
    this.properties = properties;
    this.timeOutLimit = this.state.timeOut;
    this.timesRegister = this.state.times;
    this.timeOutToSave = null;
    this.values = [];
    this.queues = []; // queues of register
    this.index = -1;
    this.lockFire = false;
    this.historyProcessing = false;

    // canvas
    const c = fabric.Canvas.prototype.canvas(this.state.name);
    // json
    // const json = JSON.stringify(c.toJSON(this.properties)); // string
    const json = c.toJSON(this.properties); // object
    // push no history
    this.values.push(json);
    // add index
    this.index++;

    // add event
    if(this.state.addEvents){
      // const events = {
      //   "object:added": this.register,
      //   "object:removed": this.register,
      //   "object:modified": this.register,
      //   'object:skewing': this.register,
      // }
      // this.off(events);
      // this.on(events);
    };

  };

  keysDown = (c, event) => {
    // Ctrl + Z
    if (event.keyCode === 90 && event.ctrlKey) this.undo(c);
    // Ctrl + Y
    if (event.keyCode === 89 && event.ctrlKey) this.redo(c);    
  }

  reset = () => {
    // reset values
    this.values = [];
    this.index = -1;
   };

  lock = () => {
    this.lockFire = true; 
  }

  unlock = () => {
    this.lockFire = false; 
  }

  register = (c) => {
    
    // wait
    // if (this.historyProcessing || this.lockFire) return;

    return new Promise((resolve, reject) => {

      this.historyProcessing = true;

      // clearTimeout(this.timeOutToSave);
      // this.timeOutToSave = setTimeout(() => {
  
        // no more register, max
        if( this.index < this.values.length - 1 ){
          console.log('splice by index');
          this.values.splice( this.index + 1, this.timesRegister );
          this.index = this.values.length - 1;
        }        
        
        // remove first elements
        if( this.values.length > this.timesRegister ){
          console.log('shift by max register');
          this.values.shift();
          this.index--;
        }

        try {

          // canvas
          c || (c = fabric.Canvas.prototype.canvas(this.state.name));

          // toJSON
          // const json = JSON.stringify(c.toJSON(this.properties)); // string

          if (!c.isFabricCanvas){
            this.historyProcessing = false;
            return reject();
          }

          const json = c.toJSON(this.properties); // object
          // push no history
          this.values.push(json);
          // add index
          if(this.index < this.values.length) this.index++;
          // length
          c.registerLength = this.index;
          // log
          console.log( 'values:', this.values.length, 'index:', this.index );
  
          resolve({
            canvas: c,
          });

        } catch (error) {
          reject(error);
        }
      
      // },this.timeOutLimit);

    });
  };

  undo = (c, callback) => {

    // wait
    // if (this.historyProcessing || this.lockFire) return;
    // processing
    this.historyProcessing = true;
    
    return new Promise((resolve, reject) => {

      try {

        // canvas
        c || (c = fabric.Canvas.prototype.canvas(this.state.name));

        // import
        if( this.values.length > 0 && this.index > 0 ){
          this.index--;
          // Error: "TypeError: path.shouldCache is not a function"
          // Resolve problem clipPath
          // https://github.com/fabricjs/fabric.js/issues/5266
          // const json = JSON.parse(this.values[this.index]);
          const json = this.values[this.index];
          // json.objects.map((object) => {
          //   if (object.clipPath) {
          //     fabric.util.enlivenObjects([object.clipPath], function(arg1) {
          //       object.clipPath = arg1[0];
          //     });
          //   }
          //   return object;
          // });
          // c.clear();

          if(!c.isFabricCanvas) {
            this.index++;
            this.historyProcessing = false;
            return reject();
          }
  
          this.state.artboard.removeEventListenerArtboard(c);
          c.loadFromJSON(json, () => {

            this.state.artboard.addEventListenerArtboard(c);
            c.renderAll.bind(c);

            // ---------------------------------------------------------------------------
            // length
            c.registerLength = this.index;
            // allow
            this.historyProcessing = false;
            // callback
            if (callback !== undefined && typeof callback === 'function') callback();
            // log
            console.log( 'values:', this.values.length, 'index:', this.index );
            UtilsCanvas.renderAll(c);
            // ---------------------------------------------------------------------------
            
            resolve();

          }, (js, ob) => {
            ob.ownCaching = false;
            ob.objectCaching = false;
            ob.dirty = true;

            ob.clipTo = null;
            ob.clipPath = undefined;
            // ob.clipPathObject = null;
            // ob.clipPathJson = null;
            // ob.clipPathKey = null;        
          });
        }
    
      } catch (error) {
        // allow
        this.index++;
        this.historyProcessing = false;
        console.log('history:undo', error);
        reject(error);
      }

    });
      
  };

  redo = (c, callback) => {

    // wait
    // if (this.historyProcessing || this.lockFire) return;
    // processing
    this.historyProcessing = true;

    return new Promise((resolve, reject) => {
      
      try {

        // canvas
        c || (c = fabric.Canvas.prototype.canvas(this.state.name));

        if(!c) {
          this.historyProcessing = false;
          return reject();
        }

        // import
        if( this.index < this.values.length && this.values.length - 1 > this.index ){
          this.index++;
          // Error: "TypeError: path.shouldCache is not a function"
          // Resolve problem clipPath
          // https://github.com/fabricjs/fabric.js/issues/5266
          // const json = JSON.parse(this.values[this.index]);
          const json = this.values[this.index];
          // json.objects.map((object) => {
          //   if (object.clipPath) {
          //     fabric.util.enlivenObjects([object.clipPath], function(arg1) {
          //       object.clipPath = arg1[0];
          //     });
          //   }
          //   return object;
          // });
          // c.clear();

          if(!c.isFabricCanvas) {
            this.index--;
            this.historyProcessing = false;
            return reject();
          }

          this.state.artboard.removeEventListenerArtboard(c);
          c.loadFromJSON(json, () => {

            this.state.artboard.addEventListenerArtboard(c);
            c.renderAll.bind(c)

            // ---------------------------------------------------------------------------
            // allow
            this.historyProcessing = false;
            // length
            c.registerLength = this.index;
            // callback
            if (callback !== undefined && typeof callback === 'function') callback();
            // log
            console.log( 'values:', this.values.length, 'index:', this.index );
            // ---------------------------------------------------------------------------
        
            resolve();

          }, (js, ob) => {
            ob.ownCaching = false;
            ob.objectCaching = false;
            ob.dirty = true;

            ob.clipTo = null;
            ob.clipPath = undefined;
            // ob.clipPathObject = null;
            // ob.clipPathJson = null;
            // ob.clipPathKey = null;
          });
        }
  
      } catch (error) {
        // allow
        this.index--;
        this.historyProcessing = false;
        console.log('history:redo', error);
        reject(error);
      }
      
    });

  };
  
}

export default Register;